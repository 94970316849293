import React from "react";
import logo from "../../assets/images/logo2.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from '@fortawesome/free-solid-svg-icons'
import "./header.css";
import { nav } from "../../assets/data/data";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar'; 
import NavbarCollapse from 'react-bootstrap/NavbarCollapse'; 

export const Header = () => {
  window.addEventListener("scroll", function () {
    const header = this.document.querySelector(".header");
    header.classList.toggle("active", this.window.scrollY > 100);
  });

  return (
    <>
      <header className='header'>
        <Navbar collapseOnSelect expand="lg">
          <Container fluid>
            <Navbar.Brand href="/">
              <img src={logo} alt='logo' width='100px' />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav">
              <FontAwesomeIcon icon={faBars} />
            </Navbar.Toggle>
            <NavbarCollapse id="responsive-navbar-nav">
              <Nav className="justify-content-center w-100">
                {nav.map((link) => (
                  <Nav.Link href={link.url} key={link.id}>
                    {link.text}
                  </Nav.Link>
                ))}
              </Nav>
            </NavbarCollapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
};