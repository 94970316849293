import React from "react";
import "./turnoconmigo.css";
import WhatsAppWidget from "../../components/widget/WhatsappWidget"; // Asegúrate de usar la ruta correcta


export const Turnoconmigo = () => {
    return (
      <>
        <img className="mastestimonios" src="/images/nosotros/turnoconmigo.jpeg" alt="terapia con Sabrina Fioretti"/>
        <WhatsAppWidget />
      </>
    );
};

