import React from 'react';
import './WhatsAppWidget.css';

const WhatsAppWidget = () => {
  return (
    <a
      href="https://wa.link/z7u8m2" // Reemplaza con tu número de WhatsApp
      className="whatsapp_float"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" // Reemplaza con la URL del icono de WhatsApp
        alt="WhatsApp"
        className="whatsapp-icon"
      />
    </a>
  );
};

export default WhatsAppWidget;
