import React from 'react';
import CursoItem from "../../components/cursoItem/CursoItem";
import { cursosData } from '../../assets/data/cursos';
import { Col, Row, Container } from 'react-bootstrap';
import './cursos.css'
import WhatsAppWidget from "../../components/widget/WhatsappWidget"; // Asegúrate de usar la ruta correcta


const Cursos = () => {
    return (
        <>
            <Container className='containercursos'>
                <Row xs={12} md={3} lg={4} className='cursos-row'>
                    {cursosData.map((curso, idx) => (
                        <Col key={idx}>
                            <CursoItem key={curso.slug} {...curso} />
                        </Col>
                    ))}
                </Row>
            </Container>
            <WhatsAppWidget />
        </>

    );
};

export default Cursos;

