import React, { useEffect, useState } from "react";
import { Carousel, Col, Image, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import "./CustomCarousel.css";


const testimonios = [
  {
    name: "Ailen",
    image: "/images/blogs/Ailen.jpeg",
    rating: 5,
    text:
      "Sabri personalmente me has ayudado muchísimo, tanto en temas personales como laborales. La motivación que generas es hermosa y con mucha energía. Me has hecho sentir una mujer empoderada 🥰",
  },
  {
    name: "Rocio",
    image: "/images/blogs/Rocio.jpeg",
    rating: 5,
    text:
      "Hola Sabri! Veo todos tus videos y sigo tus tips! Me has ayudado a conocerme mejor y eso se traduce indudablemente no solo en las relaciones íntimas, sino en todos los aspectos de la vida. Aprender a querernos y valorarnos es el primer paso, de eso ya no me quedan dudas!",
  },
  {
    name: "Carolina",
    image: "/images/blogs/Carolina.jpeg",
    rating: 5,
    text:
      "Sabri, te mando este mensaje para agradecerte, quiero expresarte lo que siento gracias a tu escucha atenta y amorosa. Te agradezco haberme ayudado de forma tan hermosa, me diste luz a esas partes que yo erróneamente creí tener oscuras. Gracias por abrazarme con tus palabras claras y llenas de sabiduría. 🥰",
  },
  {
    name: "Ariadna",
    image: "/images/blogs/Ari.jpeg",
    rating: 5,
    text:
      "Hola Sabri! Te quiero contar que después de la charla que tuvimos me ayudaste un montón a sentirme más empoderada como mujer, a confiar en mí y en mi cuerpo. Solo tengo palabras de agradecimiento por hacerme ver que todas podemos sacar esa sensualidad que tenemos adentro. Es solo cuestión de actitud y amor propio. ❤️",
  },

];

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};
const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
};

const directionButtons = (direction) => {
  return (
    <FontAwesomeIcon
    icon={direction === "Next" ?  faChevronRight : faChevronLeft}
    size="sm"
    className="text-warning"
    />
  );
}
const CustomCarousel = () => {
  const { width } = useWindowDimensions();
  const reduceRecipes = (acc, cur, index) => {

    const size = width < 480 ? 1 : 2;
    const groupIndex = Math.floor(index / size);
    if (!acc[groupIndex]) acc[groupIndex] = [];
    acc[groupIndex].push(cur);
    return acc;
  };


  return (
    <Carousel variant="dark" nextIcon={directionButtons("Next")}
      prevIcon={directionButtons("Previous")}>
      {testimonios.reduce(reduceRecipes, []).map((item, index) => (
        <Carousel.Item key={index}>
          <div className="d-flex justify-content-center">

            {item.map((testimonio, index) => {
              return (
                <Container className='testimonial-content'>
                  <Row className="justify-content-md-center">
                    <Col xs={12} md={12}>
                      <Image src={testimonio.image} alt={testimonio.name} roundedCircle fluid />
                    </Col>
                    <Col xs={12} md={12}>
                      <h5>{testimonio.name}</h5>
                    </Col>
                    <Col xs={12} md={12}>
                      <div className="star-rating">
                        {[1, 2, 3, 4, 5].map((star) => (
                          <FontAwesomeIcon
                            key={star}
                            icon={faStar}
                            size="sm"
                            className="text-warning"
                          />
                        ))}
                      </div>
                    </Col>
                    <Col xs={12} md={12}>
                      <p>
                        <FontAwesomeIcon fas icon="quote-left" />
                        {testimonio.text}
                      </p>
                    </Col>

                  </Row>
                </Container>




              );
            })}
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default CustomCarousel;

/*<Card key={index} style={{ width: '18rem' }}>
<Card.Img
variant="top"
src={`https://picsum.photos/200?random=${item + 1}`}
/>
<Card.Body>
<Card.Title>{item.name}</Card.Title>
<Button variant="primary">Go somewhere</Button>
</Card.Body>
</Card>*/
/*<Col md={4}>
                  <div className="testimonial-content">
                  


                    <img
                      src={testimonio.image}
                      className="rounded-circle shadow-1-strong"
                      width="150"
                      height="150"
                      alt={testimonio.name}
                    />

                    
                    
                    <br />
                    
                  </div>
                </Col>*/ 