import React from "react"
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import { Footer } from "./components/footer/Footer"
import { Header } from "./components/header/Header"
import { Home } from "./pages/home/Home"
import { BlogPage } from "./pages/blog/BlogPage"
import { Contacto } from "./pages/contacto/Contacto"
import { Quienessomos } from "./pages/quienessomos/Quienessomos"
import { mobileMenu } from "./assets/data/data";
import Cursos from './pages/cursos/cursos';
import PaginaCurso from "./pages/cursos/pagina/PaginaCurso";
import CookieConsentBanner from './components/cookies/cookies';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Entradas from "./pages/details/entries/entradas";
import { Turnoconmigo } from "./pages/turno/Turnoconmigo"
import { Talleres } from "./pages/talleres/talleres"
import PaginaTalleres from "./pages/talleres/pagina/PaginaTalleres";


const App = () => {
  return (
    <>
      <Router>
      <Header mobileMenu={mobileMenu} /> 
      <CookieConsentBanner /> {/* Agregar el banner de consentimiento de cookies aquí */}
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/blog' component={BlogPage} />
          <Route exact path='/contacto' component={Contacto} />
          <Route exact path='/quienessomos' component={Quienessomos} />
          <Route path='/details/:id' component={Entradas} />
          <Route exact path='/cursos' component={Cursos} />
          <Route path='/cursos/:slug' component={PaginaCurso} />
          <Route exact path='/turnoconmigo' component={Turnoconmigo} />
          <Route exact path='/talleres' component={Talleres} />
          <Route path='/talleres/:slug' component={PaginaTalleres} />
          
        </Switch>
        <Footer />
      </Router>
    </>
  )
}
export default App

