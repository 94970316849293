import React from "react"
import { Blog } from "../../components/blog/Blog"
import WhatsAppWidget from "../../components/widget/WhatsappWidget"; // Asegúrate de usar la ruta correcta



export const BlogPage = () => {
  return ( 
    <>
    <Blog/>
    <WhatsAppWidget />

    </>
  )
}


