import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Button } from 'react-bootstrap';
import "./TalleresItem.css";


const TalleresItem = ({ slug, titulo, subtitulo, portada }) => {
  return (
    <Card>
      <Card.Img variant="top" src={portada} alt={`Portada de ${titulo}`} className='imagenportada' />
      <Card.Body className='estiloboton'>
        <Card.Title>{titulo}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{subtitulo}</Card.Subtitle>
        <Button variant="primary" size="lg">
          <Link to={`/talleres/${slug}`} className="curso-link">
            Más información
          </Link>
        </Button>
      </Card.Body>
    </Card>
  );

};

export default TalleresItem;
