export const talleresData = [
    {
        id: 1,
        titulo: "Placeres orales y manuales a un pene",
        subtitulo:'💫 Volvete un expert@ en estimular un pene, con la boca y las manos y volverte inolvidable en la cama💫',
        slug: 'taller-1',
        portada: "/images/blogs/Placeres-pene.png",
        guia: {
            titulo: 'Placeres orales y manuales a un pene',
            subtitulo:'💫 Volvete un expert@ en estimular un pene, con la boca y las manos y volverte inolvidable en la cama💫',
            items:[
                {
                    titulo: 'VAS A APRENDER: 👇',
                    items: [
                        
                        '🚀 Más de 33 formas de tocar y lamer un pene, cómo innovar y volverlo loco',
                        '🚀 Las 6 mejores posiciones innovadoras para practicar sexo oral',
                        '🚀 Incorporacion de juguetes sexuales. Cuales comprar y cómo usarlos'
                    ],
                },
                {
                    titulo:'🎁👇 EXTRA 👇🎁',
                    items: [
                        '🚀 Cómo incursionar en la estimulación anal: oral y manualmente',
                        '✅ Video grabado con explicación gráfica (con pene de goma) con ejercicios. Y ejemplos.',
                        '✅ Manual PDF de guia, con imagenes de posiciones sexuales y ejercicios. Para que lo tengas siempre cerca',
                        '✅ Acompañamiento de Sabri durante 2 semanas. Para consultas y dudas.',
                        '✅ Video grabado con explicación gráfica (con pene de goma) con ejercicios y ejemplos.',
                        
                    ],
                },
                {
                    titulo:'🎁 Viene de regalo una guía de juguetes y accesorios sexuales para que elijas como llevar tu intimidad al siguiente nivel 😉',
                    items: [
                        ''
                        
                    ],
                }
            ],
            pie: 'Vos elegis cual es la mejor forma de invertir en tu intimidad💯🥰Realmente es un proceso muy hermoso!'
        }
    },


]

