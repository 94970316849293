import React from 'react';
import TalleresItem from "../../components/talleresItem/TalleresItem";
import { talleresData } from '../../assets/data/talleres';
import { Col, Row, Container } from 'react-bootstrap';
import './talleres.css'
import WhatsAppWidget from "../../components/widget/WhatsappWidget"; // Asegúrate de usar la ruta correcta


export const Talleres = () => {
    return (
        <>
            <Container className='containercursos'>
                <Row xs={12} md={3} lg={4} className='cursos-row'>
                    {talleresData.map((curso, idx) => (
                        <Col key={idx}>
                            <TalleresItem key={curso.slug} {...curso} />
                        </Col>
                    ))}
                </Row>
            </Container>
            <WhatsAppWidget/>
        </>
    );



};

// el error esta en curso.slug, cuando tenga info de talleres repasarlo.
