import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { Button, Alert } from 'react-bootstrap';

const CookieConsentBanner = () => {
  const [acceptedCookies, setAcceptedCookies] = useState(Cookies.get('acceptedCookies'));

  const handleAcceptCookies = () => {
    Cookies.set('acceptedCookies', 'true', { expires: 365 });
    setAcceptedCookies(true);
  };

  return (
    !acceptedCookies && (
      <Alert variant="light">
        <p style={{ textAlign: "center" }}>Este sitio web utiliza cookies para mejorar la experiencia del usuario. Al hacer clic en "Aceptar", aceptas el uso de cookies.</p>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
          <Button onClick={handleAcceptCookies} variant="outline-dark">Aceptar</Button>
        </div>
      </Alert>
    )
  );
};

export default CookieConsentBanner;
