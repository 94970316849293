import { talleresData } from "../../../assets/data/talleres";
import { useParams } from "react-router-dom";
import { Col, Container, Image, Row, Card, Button } from 'react-bootstrap';
import './paginaTalleres.css'


const PaginaTalleres = () => {
    const { slug } = useParams();
    const entry = talleresData.find((item) => item.slug === slug);

    if (!entry) {
        // Manejar el caso en el que no se encuentra una entrada válida
        return <div>No se encontró la entrada.</div>;
    }

    return (

        <div>
            <Container>
                <Row className="pagina-row">
                    <Col lg={8}>
                        <Row>
                            <Col lg={12} className="columnaportada">

                                <Image src={entry.portada} alt={`Portada de ${entry.titulo}`} fluid rounded className="portadacurso"/>
                            </Col>
                            <Col lg={12}>
                                <h1 className="tituloh1">{entry.guia.titulo}</h1>
                                <h2 className="tituloh2">{entry.guia.subtitulo}</h2>

                                {entry.guia.items.map((item) => (
                                    <div>
                                        <p>{item.titulo}</p>
                                        <div>
                                            {item.items.map((string, index) => (
                                                <p key={index}>{string}</p>
                                            ))}
                                        </div>
                                    </div>
                                ))}

                                <p>{entry.guia.pie}</p>

                            </Col>
                        </Row>
                    </Col>
                    <Col lg={4}>
                        <Row>
                            <Col lg={12} className="cuerpotarjeta">
                                <Card className="text-center" >
                                    <Card.Body>
                                        <Card.Title>Adquirilo ya!</Card.Title>
                                        <Card.Text className="textodelboton">
                                            Contactanos por whatsapp para adquirir el curso, despejar dudas y lo que necesites!
                                        </Card.Text>
                                        <a href="https://wa.me/5492612187822" target="_blank" rel="noopener noreferrer">
                                        <Button variant="success">Adquirilo ya!</Button> 
                                        </a>
                                    </Card.Body>
                                </Card>
                            </Col>

                        </Row>
                    </Col>
                </Row>

            </Container>
        </div>
    );
};

export default PaginaTalleres;
/* meti el button en el anchor, como puedo hacerlo de otra manera?*/ 