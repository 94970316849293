import React from "react";
import "./home.css";
//import Encontrarnos from "./dondeencontrarnos/Encontrarnos";
import CustomCarousel from "../../components/customcarousel/CustomCarousel";
import WhatsAppWidget from "../../components/widget/WhatsappWidget"; // Asegúrate de usar la ruta correcta


export const Home = () => {


  return (
    <>
    <div className="textoimagenhome">
      <div className="image-row">
        <a href="https://bysabrifioretti.com/cursos" target="_blank" rel="noopener noreferrer">
          <img className="home-image" src="/images/blogs/Portadas.png" alt="Cursos para potenciar tu sexualidad" />
        </a>
        <a href="https://bysabrifioretti.com/talleres" target="_blank" rel="noopener noreferrer">
        <img className="home-image" src="/images/blogs/Talleres.png" alt="Talleres de activación sexual" />
        </a>
      </div>

      <a href="https://example.com/page1" target="_blank" rel="noopener noreferrer">
      </a>
      </div>

      <div className="framevideo">
        <div className="video-container">
          <iframe width="754" height="400" src="https://www.youtube.com/embed/63fmYP1ZgBI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </div>
      <div className="title title-left">
        <span className="line"></span>
        <h2>Mi Historia</h2>
      </div>

      <div className="textohome">
        <p>
          Mi nombre es Sabrina Fioretti, una empresaria de 30 años originaria de Mendoza, Argentina. Desde muy joven, he sentido una fascinación por la sexualidad, lo que me llevó a organizar eventos educativos para mujeres sobre el tema.
        </p>
        <div className="textohistoria1">
          <div className="contenedorhistoria1">
            <p>
              Hace 8 años, comencé mi camino como empresaria en el mundo de las joyas, lo cual me brindó la oportunidad de conocer a miles de mujeres y escuchar sus historias.
            </p>
            <p>
              A menudo, estas conversaciones se convertían en confidencias y consejos, lo que despertó en mí una vocación innata para ayudar y guiar a las personas.
            </p>
            <p>Mi meta genuina es que todas puedan disfrutar plenamente de sus cuerpos y de sus vidas, porque lo merecen y están destinadas a ser felices en esta existencia. Es por ello que hoy decido compartir mi historia.</p>
          </div>
          <div className="imagenhome">
            <img className="editarimagen1" src="/images/nosotros/bomjoyas.jpg" alt="imagen Sabrina Fioretti" />
          </div>
        </div>

        <div className="title">
          <span className="line2"></span>
        </div>
        <div className="textohistoria">
          <div className="contenedorhistoria">
            <p>
              A pesar de mi amor por mi esposo, después de nueve años juntos, me enfrenté a la dolorosa realidad de que nuestra vida sexual carecía de la pasión que tanto anhelaba. Esta revelación me sumió en una profunda angustia y depresión, pero finalmente opté por separarme y emprender un viaje de autodescubrimiento.
            </p>
            <p>
              Durante mi tiempo como soltera, descubrí mi "súper poder": la multiorgasmia, lo que transformó completamente mi visión de mí misma y del placer.
            </p>
            <p>
              A través de esta revelación, comencé a valorarme y empoderarme de una manera que nunca antes había experimentado. Creo firmemente que todas merecemos experimentar la felicidad y el placer en todas las facetas de nuestra vida, y estoy comprometida a ser una guía en ese viaje hacia la realización personal y sexual.
            </p>
          </div>
          <div className="imagenhome">
            <img className="editarimagen" src="/images/nosotros/sabrihijo.jpg" alt="imagen Sabrina Fioretti y su hijo" />

          </div>
        </div>

        <div className="title">
          <span className="line2"></span>
        </div>
        <div className="textohistoria1">
          <div className="contenedorhistoria1">
            <p>
              Experimenté una sensación de bienestar y poder inigualable cuando confirmé para mí misma que podía. Fue una revelación que cambió mi vida por completo.
            </p>
            <p>
              Tomé medidas para cuidar mi cuerpo de una manera que nunca antes había hecho. Todo esto, motivado por una razón muy sencilla: empecé a reconocer mi PROPIO VALOR.
            </p>
            <p>
              Fue entonces cuando empecé a valorarme a mí misma y a empoderarme a partir de mis propias capacidades. Gracias al placer que me permiti experimentar, pude encontrar goce en cada faceta de mi vida.

            </p>
            <p>
              La combinación de lo que he aprendido con mis propias experiencias me impulsó a profundizar mis conocimientos y compartirlos con mujeres de todo el mundo.
            </p>
            <p>Creo firmemente que, si yo pude transformar mi vida por completo a través del disfrute y el placer en la intimidad, entonces es posible para todos. ¡Aquí estoy para ayudarte!</p>
          </div>
          <div className="imagenhome">
            <img className="editarimagen1" src="/images/nosotros/sabrinainicio.jpg" alt="imagen Sabrina Fioretti" />
          </div>
        </div>

        <div className="title">
          <span className="line2"></span>
        </div>

      </div>

      <div className="title title-left">
        <h2>Testimonios</h2>

      </div>
      <CustomCarousel className="cuerpocarousel" />

      <div className="title">
        <span className="line2"></span>
      </div>

      <div className="title title-left">
        <h2>¿Más testimonios?</h2>
      </div>
      <img className="mastestimonios" src="/images/nosotros/testimonios-01.jpg" alt="imagen Sabrina Fioretti" />

      <WhatsAppWidget /> 


    </>
  );
};

export default Home;

/*
<p>
          Te invito a visitar nuestro (local, sexshop) bla bla bla
</p>

<Encontrarnos/> 

*/
