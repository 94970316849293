import React from "react";
import { useParams } from "react-router-dom";
import { blog } from "../../../assets/data/data";
import "../entries/entradas.css";
import { Col, Container, Image, Row } from 'react-bootstrap';



const Entradas = () => {
    const { id } = useParams();
    const entry = blog.find((item) => item.id === parseInt(id));

    if (!entry) {
        // Manejar el caso en el que no se encuentra una entrada válida
        return <div>No se encontró la entrada.</div>;
    }

    return (
        <>
            <Container>
                <Row className="entry">
                    <Col lg={12} className="coverentrada">
                        <Image src={entry.cover} alt={`Portada de ${entry.title}`} fluid rounded />
                    </Col>
                    <Col lg={12}>

                        <div dangerouslySetInnerHTML={{ __html: entry.content }}></div>

                    </Col>

                </Row>
            </Container>
        </>


    );

};
export default Entradas;
