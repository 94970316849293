import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./contacto.css";
import whatsappLogo from "../../images/logos/Whatsapp.png";
import instagramLogo from "../../images/logos/Instagram.png";
import emailLogo from "../../images/logos/logo-1162901_1280.png"

export const Contacto = () => {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h2 className="contacto-h2">Contáctanos!</h2>
        </Col>
      </Row>
      <Row className="gap">
        <Col md={4}>
          <a href="https://wa.me/5492612187822" className="enlace-contacto">
            <div className="caja-contacto">
              <img src={whatsappLogo} alt="WhatsApp Logo" className="logo" />
              <h2>WhatsApp</h2>
              <p>+54 9 2612 18-7822</p>
            </div>
          </a>
        </Col>
        <Col md={4}>
          <a href="https://www.instagram.com/bysabrifioretti33/" className="enlace-contacto">
            <div className="caja-contacto">
              <img src={instagramLogo} alt="Instagram Logo" className="logo" />
              <h2>Instagram</h2>
              <p>@bysabrifioretti33</p>
            </div>
          </a>
        </Col>
        <Col md={4}>
          <a href="mailto:Sabrifioretti@gmail.com" className="enlace-contacto">
            <div className="caja-contacto">
              <img src={emailLogo} alt="Email Logo" className="logo" />
              <h2>Email</h2>
              <p>Bysabrifioretti2@gmail.com</p>
            </div>
          </a>
        </Col>
      </Row>
    </Container>
  );
};
