import React from "react"
import "./Footer.css"

export const Footer = () => {
  return (
    <>
      <footer className='footer'>
        <div className="footer-text">
          <p>&copy; 2024 Bysabrifioretti</p>
        </div>
        <div>
          <a className="privacidad" href="/politica-de-privacidad.pdf" target="_blank" rel="noopener noreferrer">
            Política de privacidad
          </a>

        </div>

      </footer>
    </>
  )
}
