
export const cursosData = [
    {
        id: 1,
        titulo: "Placeres orales y manuales a un pene",
        subtitulo:'💫 Volvete un expert@ en estimular un pene, con la boca y las manos y volverte inolvidable en la cama💫',
        slug: 'curso-1',
        portada: "/images/blogs/Placeres-pene.png",
        video: "/images/blogs/estimulacion-pene.mp4",
        guia: {
            titulo: 'Placeres orales y manuales a un pene',
            subtitulo:'💫 Volvete un expert@ en estimular un pene, con la boca y las manos y volverte inolvidable en la cama💫',
            items:[
                {
                    titulo: 'VAS A APRENDER: 👇',
                    items: [
                        
                        '🚀 Más de 33 formas de tocar y lamer un pene, cómo innovar y volverlo loco',
                        '🚀 Las 6 mejores posiciones innovadoras para practicar sexo oral',
                        '🚀 Incorporacion de juguetes sexuales. Cuales comprar y cómo usarlos'
                    ],
                },
                {
                    titulo:'🎁👇 EXTRA 👇🎁',
                    items: [
                        '🚀 Cómo incursionar en la estimulación anal: oral y manualmente',
                        '✅ Video grabado con explicación gráfica (con pene de goma) con ejercicios. Y ejemplos.',
                        '✅ Manual PDF de guia, con imagenes de posiciones sexuales y ejercicios. Para que lo tengas siempre cerca',
                        '✅ Acompañamiento de Sabri durante 2 semanas. Para consultas y dudas.',
                        '✅ Video grabado con explicación gráfica (con pene de goma) con ejercicios y ejemplos.',
                        
                    ],
                },
                {
                    titulo:'🎁 Viene de regalo una guía de juguetes y accesorios sexuales para que elijas como llevar tu intimidad al siguiente nivel 😉',
                    items: [
                        ''
                        
                    ],
                }
            ],
            pie: 'Vos elegis cual es la mejor forma de invertir en tu intimidad💯🥰Realmente es un proceso muy hermoso!'
        }
    },
    {
        id: 2,
        titulo: "Expert@ en una vulva ",
        subtitulo:'💫 Expert@ en estimular una vulva y hacerla llegar siempre al orgasmo 💫 - 💣 Sexo oral, masturbación y squirt 💣',
        slug: 'curso-2',
        portada: "/images/blogs/vulva.png",
        video: "/images/blogs/estimulacion-vulva.mp4",
        guia: {
            titulo: 'Expert@ en una vulva ',
            subtitulo:'💫 Expert@ en estimular una vulva y hacerla llegar siempre al orgasmo 💫 - 💣 Sexo oral, masturbación y squirt 💣',
            items:[
                {
                    titulo: 'VAS A APRENDER: 👇',
                    items: [
                        '🚀 Localizar punto G y como estimularlo',
                        '🚀 Todo sobre el clitoris y como darle placer',
                        '🚀 Mas de 30 formas de tocar y lamer una vagina y conseguir el orgasmo siempre',
                        '🚀 Las mejores posiciones innovadoras para practicar sexo oral',
                        '🚀 Una guia exacta para lograr el squirt, con ejercicios, y posiciones ideales para lograrlo',
                        '🚀 Incorporacion de juguetes sexuales. Cuales comprar y como usarlos',
                    ],
                },
                {
                    titulo:'🎁👇 EXTRA 👇🎁',
                    items: [
                        '✅ Video grabado con explicacion grafica (con vagina de goma) con ejercicios. Y ejemplos.',
                        '✅ Manual en PDF guia, con posiciones y ejercicios. Para que lo tengas siempre cerca',
                        '✅ Acompañamiento de sabri durante 2 semanas. Para consultas y dudas.',
                                                
                    ],
                },
                {
                    titulo:'🎁 Viene de regalo una guía de juguetes y accesorios sexuales para que elijas como llevar tu intimidad al siguiente nivel 😉',
                    items: [
                        ''
                        
                    ],
                }
            ],
            pie: 'Vos elegis cual es la mejor forma de invertir en tu intimidad. 💯🥰 Realmente es un proceso muy hermoso!'
        }
    },
    {
        id: 3,
        titulo: "Desbloqueo Orgásmico",
        subtitulo:'✅ El curso completo de 5 semanas de entrenamiento: mental, físico y espiritual para  volverte la experta del orgásmo 🚀💣',
        slug: 'curso-3',
        portada: "/images/blogs/Desbloqueoorgasmico.jpg",
        video: "/videos/desbloqueo-orgasmico.mp4",
        guia: {
            titulo: 'Desbloqueo Orgásmico',
            subtitulo:'✅ El curso completo de 5 semanas de entrenamiento: mental, físico y espiritual para  volverte la experta del orgásmo 🚀💣',
            items:[
                {
                    titulo: 'VAS A APRENDER: 👇',
                    items: [
                        '🚀 A dominar tu mente y convertirla en una aliada en tu placer y disfrute. Para que nunca más sea un impedimento',
                        '🚀 Desbloquear tus traumas, inseguridades, miedos y bloqueos emocionales, que te impiden llegar al orgásmo',
                        '🚀 Órgano sexual más importante (spoiler: los genitales, no)',
                        '🚀 Zona G y como estimularla en todas sus formas',
                        '🚀 Todo sobre el clitoris y como darle el maximo placer',
                        '🚀 Orgásmo, multiorgásmia y cómo lograrlo',
                        '🚀 Cómo guiar al varón a nuestro orgasmo',
                        '🚀 Conexión y amor en el tiempo con una pareja',
                        '🚀 Introducción al sexo tántrico',
                        '🚀 Cómo reavivar la llama',
                        '🚀 Autoestima sexual y corporal: dejando atrás la vergüenza, el miedo, la angustia',
                    ],
                },
                {
                    titulo:'¿CÓMO SE ENTREGA? 📚',
                    items: [
                        '💯 Consta de 2 clases grabadas por semana. Con ejercicios prácticos.',
                        '✅ Guía diaria de ejercicios y actividades. Para tener claridad en qué hacer cada día.',
                        '✅ Un cuadernillo en PDF, con toda la información necesaria para empezar y terminar este proceso 💪',
                        '✅ 2 clases en vídeo por semana. Con ejercicios',                    
                    ],
                },
                {
                    titulo:'Más 4 bonos de regalo: ',
                    items: [
                        '✅ Superando obstáculos en la pareja',
                        '✅ Damas y señoras de la sensualidad interna',
                        '✅ Dominando el arte del sexo oral',
                        '✅ Descubre el mundo de los juguetes sexuales',
                    ],
                },
                {
                    titulo:'💯 Más: ',
                    items: [
                        '✅ 1 sesiones de couching en vivo',
                        '✅ 2 constelaciones familiares',
                        '✅ Acompañamiento 24/7 por mí durante las 5 semanas',
                    ],
                },
                {
                    titulo:'',
                    items: [
                        ''
                    ],
                }
            ],
            pie: 'Vos elegis cual es la mejor forma de pagar el cambio de tu vida 💯🥰 Realmente es un proceso muy hermoso! Donde no te voy a soltar la mano, te vas a sentir acompañada y contenida y vamos a llegar al foco del bloqueo que hoy te impide llegar al orgasmo. 💯🚀'
        }
    },
    {
        id: 4,
        titulo: "Estratégias para relaciones sexuales eternas",
        subtitulo:'💫 Con varios ejercicios de mindfulness, aprenderan a resolver varios conflictos, como comunicarte asertivamente y no perder el fuego de una relación 💫',
        slug: 'curso-4',
        portada: "/images/blogs/relaciones-eternas.png",
        video: "/images/blogs/relaciones-eternas.mp4",
        guia: {
            titulo: 'Estratégias para relaciones sexuales eternas',
            subtitulo:'💫 Con varios ejercicios de mindfulness, aprenderan a resolver varios conflictos, como comunicarte asertivamente y no perder el fuego de una relación 💫',
            items:[
                {
                    titulo: 'VAS A APRENDER: 👇',
                    items: [
                        '🚀 24 técnicas de sexo tantrico para aumentar y mantener la conexión con la pareja',
                        '🚀 Comunicarse asertivamente, para que ambos se sientan escuchados y comprendidos. Evitando las discusiones',
                        '🚀 Identificar las faltas de respeto y como revertirlas',
                        '🚀 Conexion sexual conciente y como mantener la chispa y el amor entre ambos',
                        '🚀 Posiciones de sexo tantrico que unen y potencian los vínculos',
                        '🚀 Introducción del yoga para parejas. Mejorar la elasticidad, la complicidad y la unión',
                    ],
                },
                {
                    titulo:'🎁👇 MATERIAL 👇🎁',
                    items: [
                        '✅ 3 clases grabadas con ejemplos y ejercicios.',
                        '✅ Manual PDF de guia. Para que lo tengas siempre cerca',
                        '✅ Acompañamiento de sabri durante 2 semanas. Para consultas y dudas.',
                                                
                    ],
                },
                {
                    titulo:'🎁 Viene de regalo una guía de juguetes y accesorios sexuales para que elijas como llevar tu intimidad al siguiente nivel 😉',
                    items: [
                        ''
                        
                    ],
                }
            ],
            pie: 'Vos elegis cual es la mejor forma de invertir en tu intimidad. 💯🥰 Realmente es un proceso muy hermoso!'
        }
    },
    {
        id: 5,
        titulo: "Mujer sensual: explora tu poder interno",
        subtitulo:'💫 Convertite en la mujer seductora y deseable que siempre deseaste ser, aumenta tu seguridad, tu autoestima sexual y muevete en la intimidad como siempre lo has deseado 💫',
        slug: 'curso-5',
        portada: "/images/blogs/mujersensual.png",
        video: "/images/blogs/mujer-sensual.mp4",
        guia: {
            titulo: 'Mujer sensual: explora tu poder interno ',
            subtitulo:'💫 Convertite en la mujer seductora y deseable que siempre deseaste ser, aumenta tu seguridad, tu autoestima sexual y muevete en la intimidad como siempre lo has deseado 💫',
            items:[
                {
                    titulo: 'VAS A APRENDER: 👇',
                    items: [
                        '🚀 Las técnicas infalibles para seducir, cómo hacerlo de forma exitosa',
                        '🚀 Qué influye en la seducción',
                        '🚀 Errores más comunes',
                        '🚀 Situaciones seductoras y como potenciarlas',
                        '🚀 Crecimiento de nuestra seguridad interna y autoestima sexual',
                        '🚀 Cómo movernos y sentirnos comodas en nuestro cuerpo',
                        '🚀 Trabajaremos el desarrollo de una mirada seductora',
                        '🚀 La vestimenta: codigo de seducción, como seducir con lo que llevamos puesto',
                    ],
                },
                {
                    titulo:'🎁👇 MATERIAL 👇🎁',
                    items: [
                        '✅ Clase grabada con ejemplos y ejercicios',
                        '✅ Manual PDF de guia. Para que lo tengas siempre cerca',
                        '✅ Acompañamiento de sabri durante 2 semanas. Para consultas y dudas.',
                                                
                    ],
                },
                {
                    titulo:'🎁 Viene de regalo una guía de juguetes y accesorios sexuales para que elijas como llevar tu intimidad al siguiente nivel 😉',
                    items: [
                        ''
                        
                    ],
                }
            ],
            pie: 'Vos elegis cual es la mejor forma de invertir en tu intimidad. 💯🥰 Realmente es un proceso muy hermoso!'
        }
    },
    {
        id: 6,
        titulo: "Guía de posiciones sexuales según el tamaño",
        subtitulo:'💫 Convertite en un experto/a con tu cuerpo o el de tu compañer@, optimizando los movimientos de la cadera y asegurando la satisfacción de ambos siempre que lo deseen💫',
        slug: 'curso-6',
        portada: "/images/blogs/posiciones.png",
        video: "/images/blogs/posiciones-segun-tamaño.mp4",
        guia: {
            titulo: 'Guía de posiciones sexuales según el tamaño',
            subtitulo:'💫 Convertite en un experto/a con tu cuerpo o el de tu compañer@, optimizando los movimientos de la cadera y asegurando la satisfacción de ambos siempre que lo deseen💫',
            items:[
                {
                    titulo: 'VAS A APRENDER: 👇',
                    items: [
                        '🚀 Más de 40 posiciones sexuales: cómo optimizarlas según el tamaño del pene y el objetivo que se busque en la intimidad (conexion, sexo oral, manual, coital o anal)',
                        '🚀 Los mejores consejos y tips para mejorar el movimiento, la presion y el empuje tanto de la mujer como del hombre',
                        '🚀 Cómo innovar en las posiciones clasicas: las claves para ellas y ellos',
                        '🚀 Los mejores consejos de sabri, basados en la experiencia.',
                    ],
                },
                {
                    titulo:'🎁👇 MATERIAL 👇🎁',
                    items: [
                        '✅ videos grabado con explicacion gráfica (con personas vestidas) Y ejemplos.',
                        '✅ manual PDF de guia, con imagenes de posiciones sexuales. Para que lo tengas siempre cerca',
                        '✅ Acompañamiento de sabri durante 2 semanas. Para consultas y dudas.',
                                              
                    ],
                },
                {
                    titulo:'🎁 Viene de regalo una guía de juguetes y accesorios sexuales para que elijas como llevar tu intimidad al siguiente nivel 😉',
                    items: [
                        ''
                        
                    ],
                }
            ],
            pie: 'Vos elegis cual es la mejor forma de invertir en tu intimidad. 💯🥰 Realmente es un proceso muy hermoso!'
        }
    },
    {
        id: 7,
        titulo: "Las 3 llaves para conseguir orgásmos",
        subtitulo:'💫 cuadernillo de la "trilogia del orgasmo" donde encontraras las claves para aprender a tener orgasmos por tu propia cuenta 💫',
        slug: 'curso-7',
        portada: "/images/blogs/3LLAVES.png",
        video: "/images/blogs/3-llaves.mp4",
        guia: {
            titulo: 'Las 3 llaves para conseguir orgasmos',
            subtitulo:'💫 cuadernillo de la "trilogia del orgásmo" donde encontraras las claves para aprender a tener orgasmos por tu propia cuenta 💫',
            items:[
                {
                    titulo: 'VAS A APRENDER: 👇',
                    items: [
                        '🚀 Cómo modificar tus creencias limitantes en productivas',
                        '🚀 Transformar las experiencisd dolorosas en honrradas',
                        '🚀 Guía completa de como alcanzar el orgasmo',
                        '🚀 Todo sobre la masturbación femenina',
                        '🚀 El ABC de las zonas erogenas femeninas y masculinas',
                        '🚀 Los mejores juguetes sexuales',
                        '🚀 Cómo guiar al hombre a mi orgásmo',
                        '🚀 Técnicas para mantener la atencion en el encuentro',
                        '🚀 Claves para una comunicación efectiva',
                        '🚀 Autoestima sexual: derribando verguenzas, miedos y juicios',
                        '🚀 10 formas de tomar la iniciativa en el encuentro',
                        'Y mucho más....'
                    ],
                },
                {
                    titulo:'¿CÓMO SE ENTREGA? 📚',
                    items: [
                        '✅ Manual PDF de guia. Para que lo tengas siempre cerca'
                                                
                    ],
                },
                {
                    titulo:'🎁 Viene de regalo una guía de juguetes y accesorios sexuales para que elijas como llevar tu intimidad al siguiente nivel 😉',
                    items: [
                        ''
                        
                    ],
                }
            ],
            pie: 'Vos elegís cuál es la mejor forma de invertir en tu intimidad. 💯🥰 Realmente es un proceso muy hermoso!'
        }
    },
    {
        id: 8,
        titulo: "💫 las 5 claves para lograr el squirt en tu primera cita💫",
        subtitulo:'💫Aprenderás a encontrar el punto óptimo, dominar los movimientos corporales e identificar las señales para lograr un squirt💫',
        slug: 'curso-8',
        portada: "/images/blogs/squirt.jpg",
        video: "/images/blogs/squirt.mp4",
        guia: {
            titulo: '💫 las 5 claves para lograr el squirt en tu primera cita💫',
            /* inventense un subtitulo*/
            items:[
                {
                    titulo: 'VAS A APRENDER: 👇',
                    items: [
                        '🚀 Encontrar el punto de estimación clave para lograr hacerlo',
                        '🚀 Cómo mover los dedos y el cuerpo',
                        '🚀 Posiciones para lograrlo',
                        '🚀 Tener un squirt durante el orgásmo',
                        '🚀 Identificar las señales para lograrlo siempre',
                    ],
                },
                {
                    titulo:'¿CÓMO SE ENTREGA? 📚',
                    items: [
                        '✅ Video grabado con explicación gráfica (con vagina de goma), con ejemplos',
                        '✅ Manual en PDF guía, con posiciones y ejercicios. Para que lo tengas siempre cerca',
                        '✅ Acompañamiento de sabri durante 2 semanas. Para consultas y dudas',
                                           
                    ],
                },
                {
                    titulo:'🎁 Viene de regalo una guía de juguetes y accesorios sexuales para que elijas cómo llevar tu intimidad al siguiente nivel 😉',
                    items: [
                        ''
                        
                    ],
                }
            ],
          
        }
    },

]


export const faq = [
    {
        pregunta: '¿Tengo acceso limitado a esta guía?',
        respuesta:'¡Sí! Luego de que realices la compra vas a poder acceder a la guía todas las veces que lo necesites.',
    },
    {
        pregunta: '¿Es un solo pago o son pagos mensuales?',
        respuesta:'Es un solo pago, la inversión se realiza por única vez.',
    },
    {
        pregunta: '¿Necesito tener conocimientos previos?',
        respuesta:'No, no es necesario tener ningún conocimiento previo para comprender ésta guía. Únicamente una computadora o celular con acceso a internet.',
    },
    {
        pregunta: '¿Quién puede comprar este curso?',
        respuesta:'Todos pueden comprar este curso no importa su orientación sexual.',
    },
    {
        pregunta: '¿En qué formato está la guía?',
        respuesta:'El contenido del curso está en formato PDF y video.',
    },
    {
        pregunta: '¿Desde qué países se puede acceder?',
        respuesta:'¡Todo el mundo! Si sos de Argentina, con transferencia bancaria. Desde el resto del mundo por paypal, binance, transferencia de usdt, dólar físico o en tres cuotas sin interés (no aplica descuento). Si tenés dudas sobre algún método de pago, consulta por whatsApp a soporte +5492615511681',
    },
    {
        pregunta: '¿Tengo otra consulta?',
        respuesta:'Podes consultar tus dudas al Soporte (+5492615511681), o al correo bysabrifioretti@gmail.com',
    },
    
] 