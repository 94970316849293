import React from "react";
import "../quienessomos/quienessomos.css";
import youtubeLogo from "../../images/logos/Youtube.png";
import whatsappLogo from "../../images/logos/Whatsapp.png";
import instagramLogo from "../../images/logos/Instagram.png";
import spotifyLogo from "../../images/logos/spotify.png";
import WhatsAppWidget from "../../components/widget/WhatsappWidget"; 

export const TeamMember = ({ name, role, description, image }) => (
  <div className="team-member">
    <img src={image} alt={name} />
    <h3>{name}</h3>
    <p>{role}</p>
    <p>{description}</p>
  </div>
);

export const Quienessomos = () => {
  return (
    <>
      <div className="quienes-somos">
        <div className="section-header">
          <h2>Conocé BySabri</h2>
          <p>
            <br></br>
            Somos Sabri y Maca, dos amigas desde hace más de 15 años. Hace 3 años, cuando me surgió la necesidad de crear este hermoso proyecto, comprendí que la mejor compañera para llevar a cabo sería Maca.
          </p>
          <p>
            Dado que ambas habíamos enfrentado varios desafíos en la sexualidad y experimentar dificultades para alcanzar el placer, nos dimos cuenta de que esta era una preocupación para ambas y que seguridad con muchas mujeres se encontrarían en la misma situación.
          </p>
          <p>
            Formamos un equipo de trabajo, distribuimos las responsabilidades y nos
            dedicamos a formarnos para construir una plataforma donde todas las
            mujeres puedan aprender más sobre sus cuerpos y disfrutar plenamente
            de los placeres de la vida. No solamente de sexualidad, sino también
            de familia, maternidad y crecimiento espiritual.
          </p>
        </div>
      </div>
      <div className="team-members">
        <TeamMember
          name="Sabrina Fioretti"
          role="Co-Fundadora"
          description="Apasionada por la educación sexual y el empoderamiento sexual."
          image="/images/nosotros/Sabrina.jpg"
        />
        <TeamMember
          name="Macarena Vivares"
          role="Co-Fundadora"
          description="Promotora del disfrute y el empoderamiento sexual en hombres y en mujeres."
          image="/images/nosotros/Macarena.jpg"

        />
        {/* Agrega más miembros del equipo según sea necesario */}
      </div>

      <div className="redes">
        <h2>Nuestras Redes</h2>
        <a href="https://www.youtube.com/@BySabriFioretti" target="_blank" rel="noopener noreferrer" className="logo-link">
          <img src={youtubeLogo} alt="YouTube Logo" className="logo" />
        </a>
        <a href="https://wa.link/z7u8m2" target="_blank" rel="noopener noreferrer" className="logo-link">
          <img src={whatsappLogo} alt="WhatsApp Logo" className="logo" />
        </a>
        <a href="https://www.instagram.com/bysabrifioretti33?igsh=ODRpcHhqZ2d0MWlu" target="_blank" rel="noopener noreferrer" className="logo-link">
          <img src={instagramLogo} alt="Instagram Logo" className="logo" />
        </a>
        <a href="https://podcasters.spotify.com/pod/show/bysabrifioretti" target="_blank" rel="noopener noreferrer" className="logo-link">
          <img src={spotifyLogo} alt="Instagram Logo" className="logo" />
        </a>
        
      </div>
      <WhatsAppWidget /> 

    </>
  );
};








