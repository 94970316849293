import Accordion from 'react-bootstrap/Accordion';
import { faq } from '../../assets/data/cursos';
import './AccordionFAQ.css'; 

function AccordionFAQ() {
  return (
    <div>
      <Accordion>
        {faq.map((item, index) => (
          <Accordion.Item key={index} eventKey={index}>
            <Accordion.Header>{item.pregunta}</Accordion.Header>
            <Accordion.Body>{item.respuesta}</Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
    
  );
}

export default AccordionFAQ;
